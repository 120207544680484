/* Basic styling for the Analysis Section */
.analysis-section {
  margin-top: 40px;
  padding-top: 40px;
  color: black; 
}

.report-summary-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
  padding-left: 10px; 
  color: black; 
}

.report-item {
  background-color: #ffe0cc;
  border: 2px solid #ff6b00;
  padding: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  transition: background-color 0.3s ease;
  color: black; 
}

.report-item:hover {
  background-color: #ffd1b3;
  color: black; 
}

/* Styling for Core Web Vitals charts */
.core-web-vitals-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px;
}

.core-web-vitals-title {
  font-size: 28px; 
  font-weight: bold;
  color: #333;
  margin-bottom: 20px; 
}

.core-web-vitals-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px; 
}

.core-web-vitals-column {
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.core-web-vitals-chart {
  position: relative;
  width: 120px;
  height: 120px; 
  border-radius: 50%;
  background: conic-gradient(
    #00ff00 0% 33%, 
    #ffcc00 33% 66%, 
    #ff0000 66% 100% 
  );
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 28px; 
  font-weight: bold;
}

.color-legend {
  font-size: 1rem;
  color: #555;
  text-align: center;
  margin-top: 10px; 
}

.color-legend div {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 10px;
  vertical-align: middle;
}

.color-legend .good {
  background-color: #00ff00; 
}

.color-legend .satisfactory {
  background-color: #ffcc00; 
}

.color-legend .poor {
  background-color: #ff0000; 
}

/* Responsive Design */

/* Medium Devices (Tablets) */
@media (max-width: 768px) {
  .core-web-vitals-chart {
    width: 100px;
    height: 100px;
  }

  .core-web-vitals-chart span {
    font-size: 24px; 
  }

  .color-legend {
    font-size: 0.9rem; 
  }
}

/* Small Devices (Mobile Phones) */
@media (max-width: 480px) {
  .core-web-vitals-chart {
    width: 80px; 
    height: 80px;
  }

  .core-web-vitals-chart span {
    font-size: 20px; 
  }

  .color-legend {
    font-size: 0.8rem; 
  }
}