.landing-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff; 
  }
  
  .landing-content {
    text-align: center;
    margin-bottom: 20px;
    color: #000; 
    opacity: 1; 
  }
  
  .recaptcha-container {
    display: flex;
    justify-content: center; 
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  