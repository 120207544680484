/* RecommendationSection.css */

/* Recommendations title styling */
.recommendation-title {
  font-size: 24px;
  font-weight: bold;
  margin: 40px 0 20px;
  text-align: left;
  padding-left: 10px; 
  color: black;
}

/* Recommendations section styling */
.recommendations-section {
  padding: 20px;
  background-color: #ffe0cc;
  border: 2px solid #ff6b00;
  color: black; 
  text-align: left;
}

.recommendations-content {
  font-size: 18px;
  color: black; 
  text-align: left;
}

.recommendations-content ul {
  list-style-type: disc; 
  margin-left: 20px; 
  padding-left: 20px; 
  color: black; 
  text-align: left; 
}

.recommendations-content li {
  margin-bottom: 10px;
  color: black; 
  text-align: left; 
}

.recommendations-content p {
  margin: 0;
  color: black; 
  text-align: left; 
}

.cta-section {
  margin-top: 30px;
  padding: 20px;
  background-color: #426B1F;
  border: 2px solid #ff6b00;
  text-align: center;
  border-radius: 5px;
  color: whitesmoke;
}

.cta-section p {
  font-size: 18px;
  color: whitesmoke;
  margin-bottom: 10px;
}

.contact-link {
  font-size: 18px;
  color: whitesmoke; 
  font-weight: bold;
  text-decoration: none;
  font-size: 19px;
}

.contact-link:hover {
  text-decoration: underline;
}

/* Responsive Design */

/* Medium Devices (Tablets) */
@media (max-width: 768px) {
  .recommendation-title {
    font-size: 20px;
    padding-left: 5px;
  }

  .recommendations-section {
    padding: 15px;
  }

  .recommendations-content {
    font-size: 16px;
  }
}

/* Small Devices (Mobile Phones) */
@media (max-width: 480px) {
  .recommendation-title {
    font-size: 18px;
    padding-left: 5px;
  }

  .recommendations-section {
    padding: 10px;
  }

  .recommendations-content {
    font-size: 14px;
  }
}
